/* src/components/Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.login-box {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-box h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #972525;
}

.login-input {
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #002366;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #d4af5d;
}

.banner-image {
  width: 50%; /* Sesuaikan lebar gambar */
  height: auto; /* Tinggi akan menyesuaikan secara proporsional */
  margin-bottom: 10px; /* Berikan jarak antara gambar dan form */
}


h1 {
  color: #002366;
  text-decoration: underline;
  text-decoration-color: gold;
}

.login-input {
  background-color: white; /* Latar belakang putih */
  border: 2px solid #002366; /* Border biru tua */
  color: #002366; /* Teks biru tua */
  padding: 10px;
  border-radius: 5px; /* Sudut sedikit melengkung */
  transition: all 0.2s ease-in-out; /* Efek transisi halus */
}

.login-input:focus {
  outline: none; /* Hilangkan outline bawaan browser */
  box-shadow: 0 0 5px rgba(255, 215, 0, 0.8); /* Bayangan emas saat aktif */ 
}

.login-input::placeholder {
  color: #696969; /* Placeholder warna abu-abu */
}

.error {
  color: #d9534f; /* Merah terang untuk pesan error */
  background-color: #f2dede; /* Latar belakang merah muda pucat */
  border: 1px solid #ebccd1; /* Border merah muda */
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px; /* Beri jarak dari elemen di atasnya */
  text-align: center;
}