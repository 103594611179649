/* src/App.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*font-family: 'Roboto', sans-serif;*/
  font-family: monospace;
  background-color: #f0f2f5;
  padding: 20px;
  border-radius: 8px;
}

.custom-file-upload {
  background-color: #007bff; /* Warna biru */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
  background-color: #0056b3; /* Warna biru yang lebih gelap saat hover */
}

.file-name {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
}

.scan-button {
  background-color: #28a745; /* Warna hijau */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.scan-button:hover {
  background-color: #218838; /* Warna hijau yang lebih gelap saat hover */
  transform: translateY(-2px); /* Efek naik sedikit saat hover */
}

.scan-button:disabled {
  background-color: #6c757d; /* Warna abu-abu saat dinonaktifkan */
  cursor: not-allowed;
}

.scan-results {
  margin-top: 20px;
  /* text-align: center; */
}

/* App.css */
.repeated-rules {
  text-align: left;
}

/* App.css */
.button-group {
  display: flex;
  gap: 10px;
}

.monospaced-button {
  font-family: "Courier New", Courier, monospace; /* Mengatur font menjadi monospaced */
}

.reset-button:hover {
  background-color: #d32f2f;
}

.reset-button {
  background-color: #3a413c; /* Warna hijau */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.reset-button:hover {
  background-color: #883e21; /* Warna hijau yang lebih gelap saat hover */
  transform: translateY(-2px); /* Efek naik sedikit saat hover */
}

.reset-button:disabled {
  background-color: #6c757d; /* Warna abu-abu saat dinonaktifkan */
  cursor: not-allowed;
}

.reset-results {
  margin-top: 20px;
  text-align: center;
}

/* App.css */
.download-button {
  background-color: #2196f3; /* Warna biru */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.download-button:hover {
  background-color: #1976d2;
  transform: translateY(-2px); /* Efek naik sedikit saat hover */
}

.header {
  margin-bottom: 5px;
}

.sub-header {
  margin-bottom: 30px;
}

.logout-button {
  margin-top: auto; /* Menempatkan tombol di bagian bawah container */
  padding: 10px 20px;
  background-color: #ff4d4d; /* Warna latar belakang merah */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  margin-top: 50px;
}

.logout-button:hover {
  background-color: #ff1a1a; /* Warna latar belakang merah lebih gelap saat hover */
}

.info-section p {
  margin-bottom: 20px;
  color: #666;
}


.guidelines-link {
  font-weight: bold;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}


.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.close-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}