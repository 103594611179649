.scan-results {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
}

.scan-results-section {
  margin-bottom: 16px;
}

.scan-results-header {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.toggle-visibility-checkbox {
  cursor: pointer;
}

.scan-results-content {
  margin-top: 16px;
  transition: visibility 0.3s, opacity 0.3s ease-out;
}

.scan-results-content.visible {
  visibility: visible;
  opacity: 1;
}

.scan-results-content.hidden {
  visibility: hidden;
  opacity: 0;
  height: 0; /* Optional: if you want to fully collapse the content */
  overflow: hidden;
}


.highlighted {
  color: red;
}

.first-called {
  color: green;
  font-weight: bold;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-text {
  font-style: italic;
  font-size: smaller;
  color: #808080; /* slightly grayish color */
  text-align: center; /* Center text horizontally */
}

.scan-results .base-trace-log {
  margin-bottom: 15px;
}

.scan-results .base-trace-log p {
  margin: 5px 0;
}

.scan-results .base-trace-log .log-details {
  word-wrap: break-word; /* Wrap long text */
  overflow-wrap: break-word; /* Wrap long text */
  max-width: 800px; /* Maximum text width */
  white-space: pre-wrap; /* Allow text to wrap into multiple lines */
}
